$text: #31232f;
$text_secondary: #786c74;
$primary: #ff5b4c;
$header_background: #cbe1f3;
$background: #f6f6f8;
$background2: #fcfcfc;
$background3: #f7f7f4;
$background4: #faf4de;
$background5: #f8f5ed;
$info: #37ac84;
$light-green: #dcf3d9;
$grey2: #644f5d;
$grey3: #786c74;
$grey4: #eeece0;
$grey6: #e8e1d4;
$grey5: #bdbfc7;
$grey10: #c3bec2;
$velvet_grey: #f6f6f8;
$deep_red: #671d12;
$white: #ffff;
$yellow: #f4f4c8;
$yellow1: #e6e788;

$breakpoint: 850px;

$tablet: 991px;
$mobile_landscape: 767px;
$mobile: 479px;

$container_width: 1200px;
