@import "../../styles/variables.scss";

.wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: white;

  @media (max-width: $breakpoint) {
    display: block;
  }
}
