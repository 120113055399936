@import "../../styles/variables.scss";

.wrapper {
  padding-top: 60px;
  padding-bottom: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  border-radius: 32px;
  border: 2px solid $yellow1;
  background-color: $yellow;

  @media (max-width: $breakpoint) {
    margin-top: 0;
    padding: 10px 20px 50px 10px;
    border-radius: 0;
    background-position: center;
  }
}

.list {
  margin-top: 24px !important;
  margin-bottom: 0 !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;

  li {
    display: flex;
    align-items: center;
    gap: 8px;

    p {
      color: $background;
      font-size: 18px;
      line-height: 26px;
    }
  }

  @media (max-width: $breakpoint) {
    grid-template-columns: 1fr;

    li p {
      font-size: 16px;
    }
  }
}

.footer_title {
  h1 {
    font-family: "Noto Serif";
    font-size: 32px;
    color: $text;
    font-weight: 500;
    line-height: 30px;

    @media (max-width: $breakpoint) {
      text-align: center;
      font-size: 18px;
    }
  }

  span {
    font-style: italic;
    font-weight: 400;
  }

  p {
    font-family: "Avenir Next";
    font-style: normal;
    line-height: 34px;
    text-align: center;
    margin-top: 32px;
    font-size: 18px;
    font-weight: 600;
    color: $text;
  }
}
