@import "../../../styles/variables";

.tier_wrapper {
  max-width: 792px;
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 24px;
  margin-bottom: 40px;

  @media (max-width: $tablet) {
    flex-direction: column-reverse;
  }
}

.tier-select_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    color: $primary;
  }
}

.main_section {
  border-radius: 32px;
  padding-top: 32px;
  padding-bottom: 48px;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .matching_discount {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: $yellow1;
    position: absolute;
    top: -20px;
  }
}

.buttons_wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 48px;
  max-width: 688px;
}

.btn {
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  background: black;
  border-radius: 100px;
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: white;
  width: 100%;
  cursor: pointer;

  &:disabled {
    cursor: default;
    color: $grey5;
  }
}

.back_button {
  color: black;
  background: white;
}

.new_back_button {
  font-weight: 600;
  border-radius: 100px;
  width: 320px;
  border: 1px solid $grey10;
}

.continue_button {
  color: white;
  background: black;
}

.new_continue_button {
  font-weight: 600;
  color: $background2;
  background: $primary;
  border-radius: 100px;
  padding: 16px 24px;
  width: 320px;
  margin: auto;
}

.initial_step {
  h1 {
    margin-bottom: 20px !important;
  }

  p {
    text-align: center;
    margin-bottom: 48px;
    color: $grey2;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
  }
}

.plan_card {
  background: white;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  max-width: 180px;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 10px;
  padding-bottom: 26px;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  p {
    margin: 0;
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 25px;
    color: $text;
  }
}

.plans_wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: center;
  margin-bottom: 64px;
}

.divider {
  width: calc(100% - 24px);
  margin-top: 7px;
  margin-bottom: 11px;
  height: 1px;
  background-color: $grey4;
}

.plan_card_selected {
  border: 2px solid $primary;
  padding-top: 8px;
  padding-bottom: 24px;
  padding-left: 0;
  padding-right: 0;
}

.step_2 {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-top: 0;
  }
}
