@import "../../styles/variables";

.wrapper {
  margin: 20px 0;
  height: 100%;
  width: 100%;

  @media (max-width: $breakpoint) {
    padding-bottom: 60px;
  }
}
