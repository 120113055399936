.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;

  p {
    position: absolute;
    top: 50%;
    transform: translate(0, 75px);
  }

  svg {
    max-width: 500px;
  }
}
