.errorMessage {
  padding: 12px 16px;
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
  background-color: #ffefef;
  border-radius: 8px;
  margin-bottom: 10px;
}
