@import "../../styles/variables.scss";

.wrapper {
  margin-bottom: 15px !important;

  li {
    display: flex;
    align-items: center;
    gap: 10px;

    &::before {
      content: "•";
      color: $text_secondary;
    }
  }

  @media (min-width: $breakpoint) {
    margin-bottom: 55px !important;
  }
}
