.affiliate_banner {
  height: 64px;

  @media (max-width: 768px) {
    height: 96px;
  }
}

.invalid_banner {
  height: 0;
}
