@import "../../styles/variables.scss";

.wrapper {
  display: flex;
  background: white;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: fit-content;
  cursor: pointer;
  margin: 32px 0;
  position: relative;

  div {
    padding: 8px 12px;
    font-family: "Avenir Next";
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: $primary;

    @media (min-width: $breakpoint) {
      align-self: flex-start;
      padding: 12px 16px;
      font-size: 24px;
      line-height: 33px;
    }
  }

  @media (min-width: $breakpoint) {
    align-self: flex-start;
  }
}

.active {
  color: white !important;
  z-index: 5;
}

.switch {
  background-color: $primary;
  border-radius: 8px;
  position: absolute;
  height: 100%;
  width: 0;
  transition: all 0.2s ease-in-out;
}
