@import "../../styles/variables.scss";

.wrapper {
  height: 100%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 32px;
  padding-right: 2px;
  background: white;
  cursor: pointer;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);
  border-radius: 24px;

  &-selected {
    padding: 22px 30px;
    padding-right: 0px !important;
    border: 2px solid $primary !important;
  }
}

.header_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;

  img {
    max-width: 168px;
    height: fit-content;
  }

  @media (max-width: $tablet) {
    display: block;
  }
}

.card_title_block {
  @media (max-width: $tablet) {
    display: flex;
    justify-content: space-between;
  }
}

.title {
  font-family: "Noto Serif";
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: $text;
}

.new_pricing_title {
  @media (max-width: $tablet) {
    width: 100px;
  }
}

.plan_title {
  font-family: "Avenir Next";
  font-weight: 500;
  line-height: 34px;
  color: $primary;
}

.price {
  margin-top: 24px;
  display: flex;
  align-items: center;

  h2 {
    font-family: "Noto Serif";
    font-weight: 600;
    font-size: 34px;
    line-height: 48px;
    color: $text;
  }

  p {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: $text;
    margin-left: 4px;
    width: max-content;
  }
}

.price_hint {
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.004em;
  color: $grey3;
}

.description {
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: $grey2;
  margin-top: 24px;

  span {
    color: $text;
    font-weight: 600;
    line-height: 24px;
  }

  button {
    border: none;
    background: transparent;
    font-weight: 600;
    padding: 0;
    text-decoration: underline;
  }

  @media (max-width: $tablet) {
    font-size: 16px;
  }
}

.price_description {
  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: right;
  }
}

.price_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  img {
    max-width: 168px;
  }
}

.wrapper-single {
  padding-right: 32px !important;
  max-width: 588px;
}

.starting_at {
  margin-top: 16px;
  color: $text_secondary;
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: $tablet) {
    margin: auto 0;
    text-align: right;
  }
}

.price_repackaging {
  margin-top: 0;
}

.wrapper_repackaging {
  border: 2px solid white;
  padding: 32px !important;

  @media (max-width: $mobile) {
    padding: 20px !important;
  }
}

.link {
  color: $text;
}

.divider {
  border: 0.5px solid $grey4;
  margin: 20px 0 0;
  width: 100%;
}
