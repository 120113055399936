@import "../../styles/variables.scss";

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 12px;
  gap: 15px;
  background: $primary;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  border: none;
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: white;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
}

.sticky {
  position: sticky;
  bottom: 20px;
  width: 100%;
}
