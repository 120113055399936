@import "../../styles/variables.scss";

.wrapper {
  color: $grey2;

  font-family: "Avenir Next";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.064px;
  padding-top: 20px;

  @media (max-width: $tablet) {
    font-size: 14px;
  }
}

.tier_feature {
  margin-top: 8px;
  display: flex;
  gap: 8px;
  font-weight: 400;
}
