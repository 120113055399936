@import "../../styles/variables";

.content {
  max-width: 500px;
  margin-right: auto;

  @media (max-width: $tablet) {
    max-width: 100%;
    margin-right: 0;
  }
}

.email {
  padding: 10px;
  background-color: #f7f7f7;
  border: 1px solid #ebebeb;
  margin: 0 0 20px;
  display: flex;
  border-radius: 5px;
}

.form {
  margin: 20px 0;
}

.label {
  font-size: 14.8px;
  color: #76767e;
  font-family: "Avenir Next";
  font-weight: 500;
}

.input {
  width: 100%;
  border-radius: 5px;
  box-shadow:
    0 1px 1px rgba(0, 0, 0, 0.03),
    0 3px 6px rgba(0, 0, 0, 0.02);
  border: 1px solid #e6e6e6;
  padding: 0.75rem;
  margin-top: 0.1rem;
  margin-bottom: 0.75rem;
  color: $grey2;

  &:focus {
    outline: solid;
    outline-color: #bedaf4;
  }
}
