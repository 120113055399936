@import "../../styles/variables.scss";

.wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  height: 100%;
  width: 100%;

  img {
    margin: auto;
    width: 40%;
  }

  @media (max-width: $breakpoint) {
    img {
      width: 70%;
    }
  }
}

.desktop_title {
  max-width: 632px;
  font-family: "Garnett";
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: $text;
  margin: 20px auto 16px auto;

  span {
    color: $primary;
  }
}

.button {
  text-decoration: none;
  font-weight: 600;
  background-color: $background2;
  color: $primary;
  border-radius: 16px;
  width: 320px;
  padding: 14px 12px;
  font-family: "Avenir Next";
  border: 1px solid$primary;
  margin: 20px auto;
  display: block;
  text-align: center;
}

.content {
  margin-top: 120px;
  max-width: 680px;
}
