@import "../../../styles/variables";

.wrapper {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  position: relative;
  height: 100%;

  & > div {
    margin-top: 8px;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.wrapper_prices {
  & > div {
    &:first-of-type {
      margin-top: 0;
    }
  }

  @media (max-width: $tablet) {
    display: flex;
    gap: 16px;
    flex-direction: column-reverse;
    margin-top: 0;
  }
}
