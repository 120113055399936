@import "../../styles/variables";

.wrapper {
  background-color: $background;
  width: 100%;
  min-height: 100vh;

  @media (max-width: $breakpoint) {
    background-color: $white;
    padding: 20px;
  }
}

.form_wrapper {
  margin: 20px 0;
  padding: 30px 50px;
  background-color: $white;
  border-radius: 20px;
  box-shadow: 0 0 27px 5px rgba(0,0,0,0.1);
  width: 600px;

  @media (max-width: $breakpoint) {
    min-width: 100%;
    padding: 0;
    width: 100%;
    margin-bottom: 0;
    box-shadow: none;
  }
}

.content {
  width: fit-content;
  margin: 20px auto;

  @media (max-width: $breakpoint) {
    margin: 0;
    width: 100%;
  }
}