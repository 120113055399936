@import "../../styles/variables";

.prices_list_wrapper {
  display: flex;
  margin-bottom: 32px;
  justify-content: center;
  gap: 24px;
  flex-direction: row-reverse;

  @media (max-width: $tablet) {
    flex-direction: column-reverse;
  }
}

.new_pricing_wrapper {
  flex-direction: column;
}