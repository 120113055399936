@import "../../styles/variables.scss";

.wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  gap: 10px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  width: 100%;

  & > p {
    white-space: break-spaces;
  }

  &-selected {
    padding: 18px 22px;
    border: 2px solid $primary;
  }
}

.price_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    color: $text;
    margin-left: 5px;
  }
}

.starting_at {
  text-align: end;
  color: $text_secondary;
  font-family: "Avenir Next";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}
