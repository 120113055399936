@import "../../styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 0;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 25px;

  button {
    margin-top: auto;
  }

  @media (min-width: $breakpoint) {
    padding-bottom: 95px;
  }
}
