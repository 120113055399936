@import "../../styles/variables.scss";

.title {
  color: $text;
  font-size: 16px;
  font-family: "Noto Serif";
  font-weight: 500;
  line-height: 32px;
}

.plan_wrapper {
  padding: 20px 25px 50px 25px;
}
