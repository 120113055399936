@import "../../styles/variables";

.wrapper {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 24px;
  margin-bottom: 40px;
  max-width: initial;

  @media (max-width: $tablet) {
    flex-direction: column-reverse;
  }
}