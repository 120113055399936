@import "../../styles/variables.scss";

.matching_discount {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: $yellow1;
  justify-content: center;
}
