@font-face {
  font-family: "Avenir Next";
  src:
    local("Avenir Next"),
    url("./AvenirNext/AvenirNext-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Avenir Next";
  src:
    local("Avenir Next"),
    url("./AvenirNext/AvenirNext-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Avenir Next";
  src:
    local("Avenir Next"),
    url("./AvenirNext/AvenirNext-DemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Avenir Next";
  src:
    local("Avenir Next"),
    url("./AvenirNext/AvenirNext-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Garnett";
  src:
    local("Garnett"),
    url("./Garnett/Garnett-RegularItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Garnett";
  src:
    local("Garnett"),
    url("./Garnett/Garnett-Semibold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Garnett";
  src:
    local("Garnett"),
    url("./Garnett/Garnett-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Noto Serif";
  src:
    local("Noto Serif"),
    url("./NotoSerif/NotoSerif-VariableFont_wdth,wght.ttf") format("truetype");
  font-weight: 500;
}
