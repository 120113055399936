@import "../../../styles/variables.scss";

.wrapper {
  width: 50%;
  padding: 30px 100px 15px 70px;
  background: white;
  min-height: 100vh;
  box-shadow: -10px 0px 15px -3px rgba(0, 0, 0, 0.1);

  @media (min-width: $container_width) {
    padding-right: 200px;
  }

  @media (max-width: $breakpoint) {
    width: 100%;
    padding: 30px;
    min-height: 100%;
    box-shadow: none;
  }
}

.moneyBackGurantee {
  text-align: center;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
