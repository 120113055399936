@import "../../../styles/variables.scss";

.content {
  max-width: 500px;
  margin-left: auto;

  @media (max-width: $tablet) {
    max-width: 100%;
    margin-left: 0;
  }
}

.section {
  width: 50%;
  min-height: 100vh;
  background-color: $background5;
  padding: 25px 70px 15px 100px;
  z-index: 1;

  @media (min-width: $container_width) {
    padding-left: 200px;
  }

  @media (max-width: $breakpoint) {
    width: 100%;
    padding: 25px 30px;
    min-height: 100%;
  }
}

.active {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(199, 199, 199, 0.8);
  padding: 0;
  z-index: 1;
}

.wrapper {
  display: flex;
  margin-top: 28px;

  @media (max-width: $breakpoint) {
    justify-content: center;
  }
}

.detailsWrapper {
  margin-bottom: 50px;

  @media (max-width: $breakpoint) {
    margin-bottom: 10px;
  }
}

.price {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;

  @media (max-width: $breakpoint) {
    margin-bottom: 10px;
    justify-content: center;
    width: 100%;
  }
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin: 20px 0;
}

.divider {
  border: 1px solid $grey4;
  margin: 20px 0 0;
  width: 100%;
}

.btn {
  background-color: $text_secondary;
}

.inputWrapper {
  display: flex;
  gap: 10px;
  width: 100%;

  @media (max-width: $breakpoint) {
    flex-direction: column;
    gap: 10px;
  }
}

.input {
  border-radius: 5px;
  border: 1px solid $grey4;
  padding: 12px;
  font-family: "Avenir Next";
  width: 100%;
}

.discountWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coupon {
  background-color: $grey4;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  width: fit-content;
  gap: 10px;
  margin-bottom: 5px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobileDetails {
  display: flex;
  align-items: center;
  gap: 5px;
}

.paymentMobileDetailsWrapper {
  background-color: $background;
  padding: 25px 30px;
}

.closeIcon {
  display: flex;
  align-items: center;
}
