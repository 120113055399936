.wrapper {
  border-radius: 20px;
  padding: 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

img {
  width: 150px;
}
