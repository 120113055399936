@import "../../styles/variables.scss";

.typography-header {
  font-family: "Noto Serif";
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  color: $text;

  span {
    color: $primary;
  }

  @media (max-width: $breakpoint) {
    margin: 24px 0;
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
  }
}

.typography-title {
  font-family: "Avenir Next";
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: $text;

  @media (min-width: $breakpoint) {
    font-family: "Noto Serif";
    line-height: 34px;
  }
}

.typography-subtitle {
  font-family: "Avenir Next";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $text;

  @media (min-width: $breakpoint) {
    font-size: 16px;
    line-height: 21px;
  }
}

.typography-price {
  font-family: "Noto Serif";
  font-weight: 500;
  font-size: 48px;
  line-height: 25px;
  color: $text;

  @media (max-width: $breakpoint) {
    font-size: 24px;
    line-height: 48px;
  }
}

.typography-large-header {
  font-family: "Noto Serif";
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  width: 100%;
  margin-top: 48px;
  margin-bottom: 32px;
  text-align: center;
  color: $text;
}

.typography-subtitle2 {
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $text_secondary;
  white-space: break-spaces;

  @media (min-width: $breakpoint) {
    font-size: 18px;
    line-height: 26px;
  }
}

.typography-strikethrough-subtitle {
  font-family: "Avenir Next";
  font-size: 14px;
  font-weight: 400;
  text-decoration: line-through;
  line-height: 18px;
  color: $text_secondary;
  margin-top: 20px;

  @media (min-width: $breakpoint) {
    font-size: 16px;
    line-height: 21px;
  }
}
