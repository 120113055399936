@import "../../styles/variables.scss";

.wrapper {
  margin-bottom: 45px;

  font-family: "Avenir Next";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: $grey2;
  text-align: center;

  @media (max-width: $breakpoint) {
    margin-top: 16px;
    margin-bottom: 8px;
    padding: 0;
    padding-bottom: 10px;
  }
}

.row {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  p,
  a {
    font-weight: 600;
    letter-spacing: 0.064px;
  }

  button {
    text-decoration: none;
    padding: 0;
    border: none;
    background-color: transparent;
  }

  .tooltip {
    padding: 25px 16px;
    width: 250px;
    background-color: white;
    opacity: 1;
    font-size: 14px;
    font-family: "Avenir Next";
    font-weight: 500;
    color: $text;
    border-radius: 20px;
    z-index: 1111;
  }
}
