@import "../../styles/variables.scss";

.logo_wrapper {
  margin: 38px 20px 56px 20px;
}

.desktop_title {
  max-width: 632px;
  font-family: "Noto Serif";
  font-weight: 500;
  font-size: 45px;
  line-height: 76px;
  text-align: center;
  color: $text;
  margin: 0 auto 48px auto;

  span {
    color: $primary;
  }
}
