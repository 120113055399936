@import "../../styles/variables.scss";

.title {
  color: $text;
  font-size: 16px;
  font-family: "Garnett";
  font-weight: 500;
  line-height: 32px;
}

.plan_wrapper {
  @media (max-width: $breakpoint) {
    margin-bottom: 60px;
  }
}

.matching_discount {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: $yellow1;
  justify-content: center;
}
