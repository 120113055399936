@import "../../styles/variables";

.original_price {
  color: $text_secondary;
  font-family: "Garnett";
  font-size: 16px;

  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.036px;
  text-decoration: line-through;

  margin-right: 4px;

  @media (min-width: $breakpoint) {
    font-size: 18px;
  }
}
