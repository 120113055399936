@import "../../styles/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 10px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  width: 100%;
  position: relative;
  min-width: 248px;
  cursor: pointer;
  background-color: white;
  border: 2px solid white;

  @media (min-width: $breakpoint) {
    height: 100%;
    gap: 0;
  }

  p {
    width: 100%;
    text-align: end;
    position: absolute;
    bottom: 8px;
    right: 24px;
    color: $grey3;

    @media (min-width: $breakpoint) {
      position: static;
      text-align: start;
    }
  }

  &-selected {
    border: 2px solid $primary;

    p {
      bottom: 6px;
      right: 22px;
    }
  }
}

.repackaging_wrapper {
  padding: 32px 24px;
  @media (max-width: $breakpoint) {
    padding: 20px 28px;
  }
}

.wrapper_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (min-width: $breakpoint) {
    flex-direction: column;
    align-items: flex-start;
  }

  div {
    display: flex;
    align-items: flex-end;
    gap: 4px;

    @media (max-width: $breakpoint) {
      align-items: center;
    }
  }
}

.card_label {
  background: $primary;
  top: 0;
  right: 24px;
  transform: translate(0, -35%);
  position: absolute;
}

.card_label-repackaging {
  right: 50%;
  transform: translate(50%, -35%);
  text-align: center;
  white-space: nowrap;

  @media (max-width: $breakpoint) {
    left: 15px;
    right: auto;
    transform: translate(0, -35%);
  }
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 6px;
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: white;

  @media (min-width: $breakpoint) {
    font-weight: 500;
  }
}

.price_label {
  background: $info;

  @media (min-width: $breakpoint) {
    font-size: 14px;
    line-height: 22px;
  }
}

.price_wrapper {
  @media (min-width: $breakpoint) {
    margin-top: 16px;
  }
}

.repackaging-price_wrapper {
  position: relative;
}

.repackaging-price_label {
  width: fit-content;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  background: rgba(55, 172, 132, 0.1);
  color: $info;
  font-family: "Avenir Next";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: -0.28px;
  text-transform: uppercase;
  position: absolute;
  top: calc(100% + 5px);
  white-space: nowrap;

  @media (max-width: $breakpoint) {
    right: 0;
  }
}
.price_after {
  color: $text;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: "Avenir Next";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.14px;
}

.price_after_container {
  width: 100%;
  text-align: right;
  margin-top: 20px;
}

.green_text {
  background-color: rgba(55, 172, 132, 0.1);
  color: #37ac84;
  font-weight: 500;
  display: inline-flex;
  max-width: fit-content;
  font-family: "Avenir Next";
  border-radius: 8px;
  padding: 4px 8px;
  margin-top: 8px;
}
