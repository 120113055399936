@import "./null_styles.scss";
@import "./variables.scss";

body {
  background-color: $background5;
}

#root {
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
}
